.gridDiv1, .gridDiv2, .gridDiv3 {
    margin-bottom: 20px;
    margin-top: 20px;
    margin-left: 3rem;
}

.width-flex {
    width: 30vw;
}

.success {
    color: forestgreen;
}

.error {
    color: darkred;
}

a {
    text-decoration: none !important;
}

.center {
    margin-top: 10vw;
    display: flex;
    justify-content: center;
}
.margin-bottom-50 {
    margin-bottom: 50px;
}
.align-center {
    text-align: center;
}
.margin-top-20 {
    margin-top: 20px;
}
.margin-bottom-20 {
    margin-bottom: 20px;
}
.section {
    padding: 25px;
    border-left: 2px solid rgba(0, 0, 0, 0.05);
    border-bottom: 2px solid rgba(0, 0, 0, 0.05);
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    border-right: 1px solid rgba(0, 0, 0, 0.05);
}
:root {
    --warning-color: #DC3545;
    --info-color: deepskyblue;
}
.inline-display {
    display: inline;
}
.warning {
    color: var(--warning-color);
}
.icon {
    margin-left: 2px;
}